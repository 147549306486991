exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acerca-js": () => import("./../../../src/pages/Acerca.js" /* webpackChunkName: "component---src-pages-acerca-js" */),
  "component---src-pages-biblioteca-js": () => import("./../../../src/pages/Biblioteca.js" /* webpackChunkName: "component---src-pages-biblioteca-js" */),
  "component---src-pages-boletin-subscribe-js": () => import("./../../../src/pages/BoletinSubscribe.js" /* webpackChunkName: "component---src-pages-boletin-subscribe-js" */),
  "component---src-pages-colabora-js": () => import("./../../../src/pages/Colabora.js" /* webpackChunkName: "component---src-pages-colabora-js" */),
  "component---src-pages-conceptos-js": () => import("./../../../src/pages/Conceptos.js" /* webpackChunkName: "component---src-pages-conceptos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-author-layout-js": () => import("./../../../src/templates/AuthorLayout.js" /* webpackChunkName: "component---src-templates-author-layout-js" */),
  "component---src-templates-book-layout-js": () => import("./../../../src/templates/BookLayout.js" /* webpackChunkName: "component---src-templates-book-layout-js" */),
  "component---src-templates-concept-layout-js": () => import("./../../../src/templates/ConceptLayout.js" /* webpackChunkName: "component---src-templates-concept-layout-js" */),
  "component---src-templates-post-layout-js": () => import("./../../../src/templates/PostLayout.js" /* webpackChunkName: "component---src-templates-post-layout-js" */)
}

